<template>
<div>
    <div class="h-handle-bg">
        <div class="h-handle-button">
            <p class="h-return">
                <el-button type="text" size="small" @click="config.isDetailDisplay = false">返回</el-button>
            </p>
            <!-- <el-button type="primary" size="small" @click="add" v-right-code="'/recallproductinfo/save'">新增</el-button> -->
            <el-button type="primary" size="small" @click="save" v-right-code="'Recallproductinfo:Edit'">保存</el-button>
        </div>
    </div>

    <div class="form-list">
        <el-form :model="dataSource" ref="_ruleForm" :rules="projectCheckRule">
            <el-form-item>
                <el-col :span="4" class="form-title">
                    <span style="color: red">*</span>货主名称：</el-col>
                <el-col :span="8">
                    <ChooseCustomer v-model="dataSource.CustomerCode" :customerName.sync="dataSource.CustomerName" :onChange="customerChange"></ChooseCustomer>
                </el-col>

            </el-form-item>

            <el-form-item>
                <el-col :span="4" class="form-title">
                    <span style="color: red">*</span>商品名称：</el-col>
                <el-col :span="8">
                    <!-- <el-input v-model="dataSource.ProductName" @click.native="showProduct()" suffix-icon="el-icon-search" readonly ></el-input> -->
                    <LookPopup v-model="dataSource.ExternalSystemName" dataTitle="商品选择"
                                        dataUrl="omsapi/product/getproductinfos" syncField='ProductName'
                                        @syncFieldEvent="syncProductEvent">
                                    </LookPopup>
                </el-col>

                <el-col :span="4" class="form-title">
                    商品中文名称：</el-col>
                <el-col :span="8">
                    <el-input v-model="dataSource.ProductName" readonly disabled></el-input>
                </el-col>

            </el-form-item>
            <el-form-item>
                <el-col :span="4" class="form-title">
                    规格：</el-col>
                <el-col :span="8">
                    <el-input v-model="dataSource.SKU" readonly disabled></el-input>
                </el-col>
                <el-col :span="4" class="form-title">
                    生产厂家：</el-col>
                <el-col :span="8">
                    <el-input v-model="dataSource.ManufacturerName" readonly disabled></el-input>
                </el-col>
            </el-form-item>
            <el-form-item>
                <el-col :span="4" class="form-title">
                    批次：</el-col>
                <el-col :span="8">
                    <el-input v-model="dataSource.ProductBatchCode"></el-input>
                </el-col>

                <el-col :span="4" class="form-title"><span style="color: red">*</span>召回时间：</el-col>
                <el-col :span="8">
                    <el-form-item prop="RecalTime">
                        <el-date-picker v-model.trim="dataSource.RecalTime" placeholder="选择日期" type="date" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                    </el-form-item>
                </el-col>

            </el-form-item>

            <el-form-item>
                <el-col :span="4" class="form-title">描述：</el-col>
                <el-col :span="20">
                    <el-form-item prop="Remark">
                        <el-input type="textarea" maxlength="200" :rows="2" placeholder="请输入内容" v-model="dataSource.Remark">
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-form-item>

        </el-form>
    </div>
    <DialogEx :options="productOptions" title="选择商品" @onOk="onProductOk">
        <Product ref="product" :config="productConfig" @onSelectedRow="onProductSelectedRow"></Product>
    </DialogEx>
</div>
</template>

<script>
export default {
    data() {
        var _this = this;

        var checkRecalTime = function (rule, value, callback) {
            if (value == null || value.length == 0)
                return callback(new Error("召回时间不能为空"));
            callback();
        };

        var checkPriority = function (rule, value, callback) {
            if (value == null || value.length == 0)
                return callback(new Error("优先级不能为空"));
            if (value > 32767)
                return callback(new Error("优先级数值过大"));
            callback();
        };

        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                },
            },
            activeName: "tabbasic",
            activeNameConfig: "first",
            multipleSelection: [],
            isDisabled: false,
            projectCheckRule: {
                RecalTime: [{
                    validator: checkRecalTime,
                    trigger: "blur"
                }],
            },
            addruledetailOptions: {
                visible: false,
                size: "small",
                width: "40%",
            },
            productOptions: {
                visible: false,
                size: 'small'
            },

            productConfig: {
                ShopNo: '',
                ShopAccess: false,
                WarehouseNo: '',
                ProductNos: [],
                isDetailDisplay: true,
                isSelectionDisplay: true,
                isOrderOperation: true,
                type: 1,
                CustomerCode: '',
            },
            currentProductSelectedRows: {},
            currentEnterpriseSelectedRow: {},
            //选择仓库
            warehouseConfig: {
                isDetailDisplay: true,
            },
            warehouseOptions: {
                visible: false,
                size: "small",
            },
            currentWarehouseSelectedRow: {},
            queryParam: {
                PageIndex: 1,
                PageSize: 10,
            },
            configdataSource: {
                ColDefs: {},
                Result: [],
                TotalCount: 0,
            },
            inputVisible: false,
            inputValue: "",
            projectList: [],
            gainTypeList: [],
            billTypeList: [{
                label: '物流指令',
                value: 3
            }],
            billBodyList: [],
            paramFieldTypeList: [],
            paramFieldControlList: [],
            operatorList: [],
            DynamicsDataTitle: "选择列",
            paramFieldValueTypeList: [],
            boolList: [{
                    value: true,
                    label: "是"
                },
                {
                    value: false,
                    label: "否"
                },
            ],
            ParamFieldControlCode: {
                POPUP: 100,
                Select: 101,
                InputText: 102,
                MultilineInput: 103,
                Date: 104,
                SingleChoice: 105,
                CheckChoice: 106,
            },
            dynamicsdataOptions: {
                visible: false,
                size: 'small'
            },
            dynamicsdataConfig: {
                TableName: "",
                ParamFieldName: ""
            },
            ParamFieldValueTypeCode: {
                SQL: 100,
                Dictionary: 101,
                Enumeration: 102,
                Text: 103,
                DateFormat: 104,
                DateTimeFormat: 105,
                FixedOptions: 106,
            },
            ParamFieldTypeCode: {
                IntType: 1,
                VarcharType: 2,
                BooleanType: 3,
                DateType: 4,
                DateTimeType: 5,
                NumericType: 6,
            },
        };
    },
    props: {
        config: {
            isDetailDisplay: false,
        },
        dataSource: {},
    },
    computed: {},
    watch: {
        dataSource: {
            handler(curVal, oldVal) {
                this.isDisabled =
                    this.Utils.emptyGuid != this.dataSource.WarehouseProjectID;
            },
            deep: true,
        },
    },

    mounted() {
        this.Event.$on("clearEditFormofOrderAudit", () => this.resetForm);
    },

    methods: {
        //选择客户
        onEnterpriseSelectedRow: function (row) {
            this.currentEnterpriseSelectedRow = row;
        },
        showProduct: function (ProductID) {
            this.productConfig.CustomerCode = this.dataSource.CustomerCode;
            this.productOptions.visible = true;
        },
        syncProductEvent(row){
            this.onProductOk(row);
        },
        save: function () {
            var _this = this;
            _this.$refs["_ruleForm"].validate((valid) => {
                _this.isPassValidate = valid;
                if (valid) {
                    if (_this.dataSource.CustomerCode == null || _this.dataSource.CustomerCode.length == 0) {
                        _this.Utils.messageBox("未选择货主名称", "error");
                        return;
                    }

                    if (_this.dataSource.ProductID == null || _this.dataSource.ProductID == 0) {
                        _this.Utils.messageBox("未选择商品", "error");
                        return;
                    }

                    _this.$ajax.send(
                        "omsapi/recallproductinfo/save",
                        "post",
                        _this.dataSource,
                        (data) => {
                            if (data.IsSuccess) {
                                _this.Utils.messageBox(data.OperationDesc, "success");
                                _this.$parent.syncDataSource();
                                _this.Event.$emit("reloadRuleOfOrderAuditList");
                                _this.dataSource.Detail = [];
                                _this.$refs["_ruleForm"].resetFields(); //清空表单
                                _this.inputValue = "";

                                _this.$ajax.send("omsapi/recallproductinfo/init", "get", {}, (data) => {
                                    if (data.Result != null) {
                                        data.Result.EnableStatus = data.Result.EnableStatus.toString();
                                        _this.dataSource = data.Result;
                                    }
                                });
                            } else {
                                _this.Utils.messageBox(data.OperationDesc, "error");
                            }
                        }
                    );
                } else {
                    return false;
                }
            });
        },
        onProductOk: function (item) {

            var _this = this;
                var newRow = {
                    ProductIndex: this.productIndex,
                    UnitList: item.UnitList,
                    ProductID: item.ProductID,
                    ProductNo: item.ProductNo,
                    ProductName: item.ProductName,
                    OuterID: item.OuterID,
                    SKU: item.SKU,
                    ProductBarCode: item.ProductCode,
                    OrderProductUnit: item.ProductUnitName,
                    OrderNumber: (item.ProductNumber < 0 ? 0 : item.ProductNumber),
                    InventoryType: 1, //(this.dataSource.DefaultInventoryType == null ? this.dataSource.InventoryTypes[0].value : parseInt(this.dataSource.DefaultInventoryType)),
                    ProductPrice: item.CostPrice,
                    GrossWeight: item.GrossWeight,
                    ProductLong: item.ProductLong,
                    ProductWidth: item.ProductWidth,
                    ProductHeight: item.ProductHeight,
                    ProductVolume: item.ProductVolume
                };

                _this.dataSource.ProductName = item.ProductName;
                _this.dataSource.ProductEnglishName = item.ProductEnglishName;
                _this.dataSource.PinYinCode = item.PinYinCode;
                _this.dataSource.MnemonicCode = item.MnemonicCode;
                _this.dataSource.ManufacturerName = item.Manufacturer;
                _this.dataSource.ABCType = item.ABCType;
                _this.dataSource.SKU = item.SKU;
                _this.dataSource.ProductID = item.ProductID;
                _this.dataSource.ProductNo = item.ProductNo;
                _this.dataSource.ProductCode = item.ProductCode;
                _this.dataSource.ApprovalNumber = item.ApprovalNumber;
                _this.dataSource.ValidDay = item.ValidDay;
                _this.dataSource.ProductTypeCode = item.ProductTypeCode;
                _this.dataSource.StorageConditonName = item.StorageConditonName;
                _this.dataSource.ProductMemo = item.ProductMemo;

                _this.$ajax.send(
                    "omsapi/product/getproductinfobyid",
                    "post", {
                        ProductID: item.ProductID
                    },
                    (data) => {
                        if (data.IsSuccess) {
                            var extetial = data.Result.ProductExtend;

                            if (extetial != null) {
                                _this.dataSource.IsSpecialShaped = extetial.IsSpecialShaped;
                                _this.dataSource.IsSpecialDrug = extetial.IsSpecialDrug;

                                _this.dataSource.IsImportantProtect = extetial.IsImportantProtect;
                                _this.dataSource.IsSupervise = extetial.IsSupervise;
                                _this.dataSource.ElSuperviseCode = extetial.ElSuperviseCode;
                                _this.dataSource.IsPreventTamper = extetial.IsPreventTamper;
                                _this.dataSource.IsImport = extetial.IsImport;

                                _this.dataSource.Purchaser = extetial.Purchaser;
                                _this.dataSource.IsDoubleQc = extetial.IsDoubleQc;
                                _this.dataSource.IsDoubleCheck = extetial.IsDoubleCheck;
                                _this.dataSource.ListingPermitHolder = extetial.ListingPermitHolder;
                                _this.dataSource.IsEasyChange = extetial.IsEasyChange;

                                _this.dataSource.IsFirstSale = extetial.IsFirstSale;
                                _this.dataSource.IsExpensive = extetial.IsExpensive;
                                _this.dataSource.IsNeedCheckReport = extetial.IsNeedCheckReport;
                                _this.dataSource.IsImportant = extetial.IsImportant;
                                _this.dataSource.MinInvoicingUnit = extetial.MinInvoicingUnit;

                                _this.dataSource.PrescriptionType = extetial.PrescriptionType;
                                _this.dataSource.IsProteinPeptide = extetial.IsProteinPeptide;
                            }
                        }
                    }
                );
            _this.currentProductSelectedRows.length = 0; //清空已选择信息
        },
        onProductSelectedRow: function (data) {
            this.currentProductSelectedRows = data;
        },

        handleClose(tag) {
            this.dataSource.dynamicTags.splice(
                this.dataSource.dynamicTags.indexOf(tag),
                1
            );
        },

        showInput() {
            this.inputVisible = true;
            this.$nextTick((_) => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },

        handleInputConfirm() {
            let inputValue = this.inputValue;
            if (inputValue) {
                this.dataSource.dynamicTags.push(inputValue);
            }
            this.inputVisible = false;
            this.inputValue = "";
        },
        resetForm() {
            !this.isPassValidate && this.$refs["_ruleForm"].resetFields(); //清空表单
            this.dataSource.Detail = [];
            this.dataSource.EnableStatus = "100";
            this.dataSource.TransportOrderStatus = false;
            this.dataSource.PhurchaseOrderStatus = false;
            this.dataSource.SaleOrderStatus = false;
        },
        reloadPageList: function () {
            this.initialize();
        },
        initialize() {

        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
            this.$emit("onCheckRow", this.multipleSelection);
        },
        customerChange() {
            //this.warehouseConfig.CustomerCode=this.dataSource.CustomerCode
            //this.productConfig.CustomerCode=this.dataSource.CustomerCode
        },

    },
    components: {
        "Product": resolve => {
            require(['../../../selector/productselector.vue'], resolve)
        },
    },
};
</script>

<style>
.el-tag+.el-tag {
    margin-left: 10px;
}

.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}
</style>
